'use client';

import React from 'react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

const Layout = ({ children }: { children: React.ReactNode }) => {
  const pathname = usePathname();

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Prayer Times', path: '/prayer-times' },
    { name: 'Ask Imam', path: '/ask-imam' },
    { name: 'Daily Duas', path: '/daily-duas' },
    { name: 'Duas by Occasion', path: '/duas-by-occasion' },
    { name: 'Announcements', path: '/announcements' },
    { name: 'YouTube', path: '/youtube' },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-emerald-700 text-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link href="/" className="font-bold text-xl">
                Al Azhar Islamic Foundation
              </Link>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              {navItems.map((item) => (
                <Link
                  key={item.path}
                  href={item.path}
                  className={`px-3 py-2 rounded-md text-sm font-medium ${
                    pathname === item.path
                      ? 'bg-emerald-800 text-white'
                      : 'text-emerald-50 hover:bg-emerald-600'
                  }`}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="md:hidden flex items-center">
              {/* Mobile menu button - to be implemented */}
              <button className="text-white hover:text-gray-200">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="flex-grow container mx-auto px-4 py-8">{children}</main>

      <footer className="bg-emerald-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <div>
              <h3 className="text-xl font-bold mb-4">Contact Us</h3>
              <p className="mb-2">Al-Azhar Islamic Foundation</p>
              <p className="mb-2">160 Hawthorne Rd</p>
              <p className="mb-2">Barrington, IL 60010</p>
              <p className="mb-2">
                <a href="tel:8473873380" className="hover:underline">(847) 387-3380</a>
              </p>
              <p>
                <a href="https://aifcenter.org" target="_blank" rel="noopener noreferrer" className="hover:underline">
                  aifcenter.org
                </a>
              </p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Prayer Times</h3>
              <p className="mb-2">Updated daily</p>
              <p className="mb-2">Check our prayer times page for the latest schedule</p>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="/prayer-times" className="hover:underline">Prayer Times</a></li>
                <li><a href="/announcements" className="hover:underline">Announcements</a></li>
                <li><a href="/daily-duas" className="hover:underline">Daily Duas</a></li>
                <li><a href="/youtube" className="hover:underline">YouTube Channel</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-4">Hours</h3>
              <p className="mb-2">Open for all five daily prayers</p>
              <p className="mb-2">Jumu&apos;ah Prayer: Opens 1 PM Friday</p>
              <p>Please check prayer times for specific timings</p>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-emerald-700 text-center">
            <p>&copy; {new Date().getFullYear()} Al-Azhar Islamic Foundation. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout; 
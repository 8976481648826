export interface ImageConfig {
  src: string;
  alt: string;
  width: number;
  height: number;
}

export const images = {
  hero: {
    src: '/images/hero.jpg',
    alt: 'Masjid exterior view',
    width: 408,
    height: 306,
  },
  logo: {
    src: '/images/logo.png',
    alt: 'Masjid logo',
    width: 200,
    height: 200,
  },
  favicon: {
    src: '/images/favicon.ico',
    alt: 'Website favicon',
    width: 32,
    height: 32,
  },
} as const; 
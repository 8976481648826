import Image from 'next/image';
import { ImageConfig } from '../config/images';

interface OptimizedImageProps extends ImageConfig {
  className?: string;
  fill?: boolean;
  priority?: boolean;
}

export default function OptimizedImage({
  src,
  alt,
  width,
  height,
  className = '',
  fill = false,
  priority = false,
}: OptimizedImageProps) {
  return (
    <Image
      src={src}
      alt={alt}
      width={!fill ? width : undefined}
      height={!fill ? height : undefined}
      className={className}
      priority={priority || src.includes('hero') || src.includes('logo')}
      quality={90}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
      fill={fill}
    />
  );
} 
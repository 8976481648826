"use client";
import React, { useEffect, useState } from 'react';
import Image from "next/image";
import Layout from './components/Layout';
import { images } from './config/images';
import OptimizedImage from './components/OptimizedImage';

interface PrayerTime {
  date: string;
  fajrAdhan: string;
  fajrIqamah: string;
  sunrise: string;
  dhuhrAdhan: string;
  dhuhrIqamah: string;
  asrAdhan: string;
  asrIqamah: string;
  maghribAdhan: string;
  maghribIqamah: string;
  ishaAdhan: string;
  ishaIqamah: string;
}

interface DisplayPrayerTime {
  name: string;
  adhan: string;
  iqamah: string;
}

export default function HomePage() {
  const [prayerTimes, setPrayerTimes] = useState<PrayerTime[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchPrayerTimes() {
      try {
        const response = await fetch('/api/prayer-times');
        if (!response.ok) {
          throw new Error('Failed to fetch prayer times');
        }
        const data = await response.json();
        setPrayerTimes(data.prayerTimes);
      } catch (err) {
        console.error('Error fetching prayer times:', err);
        setError('Failed to load prayer times');
      } finally {
        setLoading(false);
      }
    }

    fetchPrayerTimes();
  }, []);

  const today = prayerTimes[0];
  const displayPrayerTimes: DisplayPrayerTime[] = today ? [
    { name: 'Fajr', adhan: today.fajrAdhan, iqamah: today.fajrIqamah },
    { name: 'Sunrise', adhan: today.sunrise, iqamah: '-' },
    { name: 'Dhuhr', adhan: today.dhuhrAdhan, iqamah: today.dhuhrIqamah },
    { name: 'Asr', adhan: today.asrAdhan, iqamah: today.asrIqamah },
    { name: 'Maghrib', adhan: today.maghribAdhan, iqamah: today.maghribIqamah },
    { name: 'Isha', adhan: today.ishaAdhan, iqamah: today.ishaIqamah },
  ] : [];

  return (
    <Layout>
      <div className="space-y-8">
        {/* Hero Section */}
        <div className="relative rounded-lg overflow-hidden" style={{ height: 'min(70vh, 500px)' }}>
          <div className="absolute inset-0 bg-black/50 z-10 flex items-center justify-center">
            <div className="text-center text-white px-4">
              <h1 className="text-4xl md:text-6xl font-bold mb-4">Welcome to Al-Azhar Islamic Foundation</h1>
              <p className="text-xl md:text-2xl">160 Hawthorne Rd, Barrington, IL 60010</p>
              <p className="text-xl md:text-2xl mt-2">
                <a href="tel:8473873380" className="hover:underline">(847) 387-3380</a>
              </p>
            </div>
          </div>
          <div className="absolute inset-0">
            <OptimizedImage
              {...images.hero}
              className="w-full h-full object-cover"
              priority
              fill
            />
          </div>
        </div>

        {/* Prayer Times Section */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-bold text-emerald-800 mb-4">Today&apos;s Prayer Times</h2>
          {loading ? (
            <p className="text-emerald-700">Loading prayer times...</p>
          ) : error ? (
            <p className="text-red-600">{error}</p>
          ) : displayPrayerTimes.length === 0 ? (
            <p className="text-emerald-700">No prayer times available</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {displayPrayerTimes.map((prayer) => (
                <div
                  key={prayer.name}
                  className="bg-emerald-50 p-4 rounded-lg border border-emerald-100"
                >
                  <h3 className="font-semibold text-emerald-900">{prayer.name}</h3>
                  <div className="mt-2 space-y-1">
                    <p className="text-emerald-700">Adhan: {prayer.adhan}</p>
                    <p className="text-emerald-700">Iqamah: {prayer.iqamah}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Features Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-emerald-800 mb-3">Islamic Classes</h3>
            <p className="text-gray-600">
              Join our weekly Quran and Islamic studies classes for all age groups.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-emerald-800 mb-3">Community Events</h3>
            <p className="text-gray-600">
              Regular community gatherings, iftars, and special events throughout the year.
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold text-emerald-800 mb-3">Youth Programs</h3>
            <p className="text-gray-600">
              Engaging activities and educational programs for our youth.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
